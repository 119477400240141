class LinkSection extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.addEventListener('mouseenter', this.mouseEnter.bind(this), { capture: true, passive: true });
    this.addEventListener('mouseleave', this.mouseLeave.bind(this), { capture: true, passive: true });
    this.addEventListener('mousemove', this.mouseMove.bind(this), { capture: true, passive: true });
  }

  disconnectedCallback() {
    this.addEventListener('mouseenter', this.mouseEnter.bind(this), { capture: true, passive: true });
    this.addEventListener('mouseleave', this.mouseLeave.bind(this), { capture: true, passive: true });
    this.addEventListener('mousemove', this.mouseMove.bind(this), { capture: true, passive: true });
  }

  mouseEnter(ev) {
    if(window.innerWidth > 800) {
      let arrow = ev.currentTarget.querySelector('.arrow');

      arrow.style.opacity = 1;
      ev.target.style.cursor = 'none';
    }
  }

  mouseLeave(ev) {
    if(window.innerWidth > 768) {
      let arrow = ev.currentTarget.querySelector('.arrow');

      arrow.style.opacity = 0;
      ev.target.style.cursor = null;
    }
  }

  mouseMove(ev) {
    if(window.innerWidth > 768) {
      let arrow = ev.currentTarget.querySelector('.arrow');

      const lftOffset = ev.clientX - ev.currentTarget.getBoundingClientRect().left - (arrow.getBoundingClientRect().width / 2);
      const topOffset = ev.clientY - ev.currentTarget.getBoundingClientRect().top - (arrow.getBoundingClientRect().height / 2);

      arrow.style.transform = "translate(" + lftOffset + "px," + topOffset + "px)";
    }
  }
}
customElements.define('link-section', LinkSection);